<template>
  <section id="FleetCreatorSection">
    <vue-headful title="FLPN Aviasolutions - List Fleets"></vue-headful>
    <h1 class="title">{{ title }}</h1>
    <div class="columns is-centered">
      <div class="column is-12">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <span>{{ fleet_name }}</span>
            </p>
          </header>
          <div class="card-content">
            <form action="#" autocomplete="off" v-on:submit.prevent="save">
              <div class="columns">
                <div class="column is-3" v-if="$auth.check(['admin'])">
                  <b-field label="Name">
                    <b-input required v-model="fleet.name"></b-input>
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field label="Taxi In">
                    <b-input required type="number" min="0" step="100"
                             v-model="fleet.taxi_in"></b-input>
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field label="Taxi Out">
                    <b-input required type="number" min="0" step="100" max="999999"
                             v-model="fleet.taxi_out"></b-input>
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field label="Minimum Fuel">
                    <b-input required type="number" min="0" step="1000" max="999999"
                             v-model="fleet.min_fuel_wt"></b-input>
                  </b-field>
                </div>
                <div class="column is-3" v-if="$auth.check(['admin'])">
                  <b-field label="Client">
                    <b-select v-model="fleet.client_id" placeholder="Select a Client" expanded
                              required>
                      <option
                          v-for="client in clients"
                          :value="client.id"
                          :key="client.id">
                        {{ client.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="columns is-centered">
                <div class="column is-2">
                  <div class="label">Weight Unit</div>
                  <div class="block">
                    <b-radio v-model="fleet.weight_unit"
                             name="weight_unit"
                             native-value="KG"
                             required>
                      KG
                    </b-radio>
                    <b-radio v-model="fleet.weight_unit"
                             name="weight_unit"
                             native-value="LB">
                      LB
                    </b-radio>
                  </div>
                </div>
                <div class="column is-2">
                  <b-field label="Contingency Weight">
                    <b-input required type="number" min="0" max="999999"
                             v-model="fleet.contingency_weight"></b-input>
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field label="Contingency Percent">
                    <b-field>
                      <b-input expanded required type="number" step="1" min="0" max="100"
                               v-model="fleet.contingency_pct"></b-input>
                      <p class="control">
                        <i class="button">
                          <b-icon icon="percent"></b-icon>
                        </i>
                      </p>
                    </b-field>
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field label="Contingency Time"
                  >
                    <b-input required type="number" min="0" max="999999"
                             v-model="fleet.contingency_time"></b-input>
                  </b-field>
                </div>
              </div>
              <hr/>

              <div class="columns">
                <div class="column is-2"></div>
                <div class="column is-4">
                  <h2 class="subtitle">Time Allowances</h2>
                  <div class="columns">
                    <div class="column is-6">
                      <b-checkbox v-model="fleet.allowance_time.climbOut.check"
                                  @click.native="fleet.allowance_time.climbOut.fixed_bias = 0">Climb Out
                      </b-checkbox>
                    </div>
                    <div class="column is-6">
                      <b-input required type="number" step="0.0001" v-model="fleet.allowance_time.climbOut.fixed_bias"
                               :disabled="!fleet.allowance_time.climbOut.check"></b-input>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-6">
                      <b-checkbox v-model="fleet.allowance_time.approach.check"
                                  @click.native="fleet.allowance_time.approach.fixed_bias = 0">Approach
                      </b-checkbox>
                    </div>
                    <div class="column is-6">
                      <b-input required type="number" step="0.0001" v-model="fleet.allowance_time.approach.fixed_bias"
                               :disabled="!fleet.allowance_time.approach.check"></b-input>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-6">
                      <b-checkbox v-model="fleet.allowance_time.missingApproach.check"
                                  @click.native="fleet.allowance_time.missingApproach.fixed_bias = 0">Missing Approach
                      </b-checkbox>
                    </div>
                    <div class="column is-6">
                      <b-input required type="number" step="0.0001"
                               v-model="fleet.allowance_time.missingApproach.fixed_bias"
                               :disabled="!fleet.allowance_time.missingApproach.check"></b-input>
                    </div>
                  </div>

                </div>
                <div class="column is-4">
                  <h2 class="subtitle">Fuel Allowances</h2>
                  <div class="columns">
                    <div class="column is-6">
                      <b-checkbox v-model="fleet.allowance_fuel.climbOut.check"
                                  @click.native="fleet.allowance_fuel.climbOut.fixed_bias = 0">Climb Out
                      </b-checkbox>
                    </div>
                    <div class="column is-6">
                      <b-input required type="number" step="0.0001" v-model="fleet.allowance_fuel.climbOut.fixed_bias"
                               :disabled="!fleet.allowance_fuel.climbOut.check"></b-input>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-6">
                      <b-checkbox v-model="fleet.allowance_fuel.approach.check"
                                  @click.native="fleet.allowance_fuel.approach.fixed_bias = 0">Approach
                      </b-checkbox>
                    </div>
                    <div class="column is-6">
                      <b-input required type="number" step="0.0001" v-model="fleet.allowance_fuel.approach.fixed_bias"
                               :disabled="!fleet.allowance_fuel.approach.check"></b-input>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-6">
                      <b-checkbox v-model="fleet.allowance_fuel.missingApproach.check"
                                  @click.native="fleet.allowance_fuel.missingApproach.fixed_bias = 0">Missing Approach
                      </b-checkbox>
                    </div>
                    <div class="column is-6">
                      <b-input required type="number" step="0.0001"
                               v-model="fleet.allowance_fuel.missingApproach.fixed_bias"
                               :disabled="!fleet.allowance_fuel.missingApproach.check"></b-input>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field notification is-default is-grouped is-grouped-right">
                <p class="control">
                  <router-link :to="'/fleets'" class="button is-light">
                    <span class="has-text-weight-bold is-uppercase">Back to Fleets</span>
                  </router-link>
                </p>

                <div class="control" v-if="$auth.check(['admin'])">
                  <button type="button" class="button is-danger" @click="dropFleet()">
                    <span class="has-text-weight-bold is-uppercase">Delete</span>
                  </button>
                </div>

                <p class="control">
                  <button type="submit" class="button is-primary">
                    <span class="has-text-weight-bold is-uppercase">Save</span>
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FleetsService from "../../services/fleets.service";
import ClientsService from "../../services/clients.service";

export default {
  data() {
    return {
      pageTitle: 'Fleet',
      fleets: [],
      fleetsService: new FleetsService(),
      clientsService: new ClientsService(),
      flightPlan: {},
      fleet: {},
      clients: [],
      fleet_name: '',
      title: 'Create Fleet',
      loaded: false
    };
  },
  mounted: function () {
    this.clientsService.findAll().then(
        ({data}) => {
          this.clients = data.data;
        }
    );
    if (this.$route.params.id) {
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.fleetsService.edit(this.$route.params.id).then(({data}) => {
            this.fleet_name = data.data.name;
            this.title = 'Edit Fleet';
            this.fleet = data.data;
            loadingComponent.close();
          },
          () => {
            loadingComponent.close();
            this.$buefy.toast.open({
              message: 'An error occurred while trying to load a fleet.',
              type: 'is-danger'
            });
          });
    }
  },
  methods: {
    save: function () {
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.fleetsService.save(this.fleet).then(
          ({data}) => {
            loadingComponent.close();
            this.fleet = data.fleet;
            this.fleet_name = data.fleet.name;
            if (this.fleet && this.fleet.id) {
              this.$router.push(`/fleets/${this.fleet.id}/edit`);
              this.title = 'Edit Fleet';
            }
            this.$buefy.toast.open({
              message: data.notification,
              type: 'is-success'
            });
          },
          (failed) => {
            loadingComponent.close();
            const messages = failed.statusText
            this.$buefy.toast.open({
              duration: 5000,
              message: messages,
              type: 'is-danger'
            });
          }
      );
    },
    dropFleet: function () {
      this.fleetsService.delete(this.fleet.id).then(() => {
        this.$router.push(`/fleets`);
        this.$buefy.toast.open({
          message: 'Fleet deleted successfully',
          type: 'is-success'
        });
      })
    }
  }
};
</script>
<style>
#FleetCreatorSection .card-header {
  background-color: lightgrey;
}
</style>